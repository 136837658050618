import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/named
import { paginateArray } from '@/constants/utils'

export default function useGameList() {
  // Use toast
  const { PER_PAGE_OPTIONS, PER_PAGE } = constants
  const refGameListTable = ref(null)
  const mixin = mixinDate.methods
  const toast = useToast()
  // Table Handlers
  const gameTableColumns = [
    { key: 'Name', sortable: false },
    { key: 'Category', sortable: false },
    { key: 'StartDate', sortable: false },
    { key: 'EndDate', sortable: false },
    { key: 'Status', sortable: false },
    { key: 'Actions' },
  ]
  const perPage = ref(PER_PAGE)
  const totalGames = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isDataLoad = ref(true)
  const companyFilter = ref(null)
  const archiveFilter = ref(false)
  const templateCategories = ref([])
  const allGames = ref([])
  const originalAllGames = ref([])
  const allArchiveGames = ref([])
  const companies = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refGameListTable.value ? refGameListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGames.value,
    }
  })

  const refetchData = () => {
    refGameListTable.value.refresh()
  }

  const refetchList = data => {
    if (data.isArchive === -1) {
      // eslint-disable-next-line no-param-reassign
      data.isArchive = 0
      allArchiveGames.value = allArchiveGames.value.filter(obj => obj.ID !== data.ID)
      allGames.value.push(data)
      originalAllGames.value.push(data)
    } else {
      // eslint-disable-next-line no-param-reassign
      data.isArchive = -1
      allGames.value = allGames.value.filter(obj => obj.ID !== data.ID)
      originalAllGames.value = originalAllGames.value.filter(obj => obj.ID !== data.ID)
      allArchiveGames.value.push(data)
    }
    refetchData()
  }

  watch([currentPage, perPage, searchQuery, companyFilter, archiveFilter], () => {
    refetchData()
  })

  const filteredData = () => {
    let data = {}
    const queryLowered = searchQuery.value.toLowerCase()
    if (searchQuery.value !== '' && companyFilter.value !== null) {
      data = allGames.value.filter(
        game => ((game.c_string_swe.toLowerCase().includes(queryLowered)) && game.companyId === companyFilter.value.companyID),
      )
    } else if (searchQuery.value === '' && companyFilter.value !== null) {
      data = allGames.value.filter(
        game => (game.companyId === companyFilter.value.companyID),
      )
    } else if (searchQuery.value !== '' && companyFilter.value === null) {
      data = allGames.value.filter(
        game => (game.c_string_swe.toLowerCase().includes(queryLowered)),
      )
    }
    return data
  }

  const fetchCompanies = () => {
    store.dispatch('app-game/fetchCompany')
      .then(response => {
        companies.value = response.data
      }).catch(() => {})
  }

  const fetchArchiveGames = () => {
    store.dispatch('app-game/fetchGameAll', {
      isArchive: -1,
    })
      .then(response => {
        allArchiveGames.value = response.data.data
      }).catch(() => {})
  }

  const fetchGamesAll = (ctx, callback) => {
    let data = {}
    if (allGames.value.length === 0) {
      fetchCompanies()
      store.dispatch('app-game/fetchGameAll', {
        isArchive: 0,
      })
        .then(response => {
          isDataLoad.value = false
          allGames.value = response.data.data
          originalAllGames.value = response.data.data
          totalGames.value = allGames.value.length
          data = paginateArray(allGames.value, perPage.value, 1)
          fetchArchiveGames()
          callback(data)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'There was an issue with fetching the game list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      if (archiveFilter.value) {
        allGames.value = JSON.parse(JSON.stringify(allArchiveGames.value))
        data = paginateArray(allGames.value, perPage.value, currentPage.value)
        totalGames.value = allGames.value.length
      } else {
        // eslint-disable-next-line no-self-assign
        allGames.value = originalAllGames.value
        data = paginateArray(allGames.value, perPage.value, currentPage.value)
        totalGames.value = allGames.value.length
      }
      if (searchQuery.value === '' && companyFilter.value === null) {
        data = paginateArray(allGames.value, perPage.value, currentPage.value)
        totalGames.value = allGames.value.length
      } else {
        const filterData = filteredData()
        totalGames.value = filterData.length
        data = paginateArray(filterData, perPage.value, currentPage.value)
      }
      callback(data)
    }
  }

  const resolveGameStatusVariant = data => {
    const today = mixin.formatDateDash(new Date())
    const startDate = mixin.formatDateDash(data.startdate)
    const endDate = mixin.formatDateDash(data.enddate)
    if (data.record_status === 0) return { variant: 'info', text: 'unpublished' }
    if (today < startDate) return { variant: 'warning', text: 'unstarted' }
    if (today >= startDate && today <= endDate) return { variant: 'success', text: 'ongoing' }
    if (today > endDate) return { variant: 'secondary', text: 'finish' }
    return { variant: 'primary', text: '' }
  }

  return {
    fetchGamesAll,
    fetchArchiveGames,
    filteredData,
    gameTableColumns,
    perPage,
    currentPage,
    totalGames,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refGameListTable,
    companyFilter,
    archiveFilter,
    refetchData,
    resolveGameStatusVariant,
    templateCategories,
    isDataLoad,
    companies,
    refetchList,
  }
}
